body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: alt;
  src: url(/static/media/ALT_REKT_SOLID.94fafd00.ttf);
}
.App {
  text-align: center;
}
.mintContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.gif {
  width: 25vw;
  height: auto;
  margin-bottom: 8vh;
  border-radius: 20px;
  box-shadow: rgba(87, 0, 29, 0.55) 0px 15px 25px;
}
.logo__image {
  width: 25vw;
  height: auto;
  margin-bottom: 4vh;
}
.MuiButton-label {
  font-size: 2.2vh !important;
  /* width: 6.7vw !important; */
  font-family: alt;

  text-align: center !important;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
}
.mint__quantity {
  font-size: 1.3vw;
  margin-bottom: 2.5vh;
  font-family: alt;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 800px) {
  .gif {
    width: 60vw;
    height: auto;
  }
  .logo__image {
    width: 60vw;
    height: auto;
  }
  .mint__quantity {
    font-size: 4vw;
    margin-bottom: 2vh;
  }
}

html {
  background-image: linear-gradient(
      to bottom,
      rgba(90, 90, 90, 0.52),
      rgba(48, 19, 117, 0.459)
    ),
    url(/static/media/bg.840f19f6.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

.MuiButton-containedPrimary {
  color: #fff;
  font-family: alt;
  background-color: #580ae2 !important;
}
.makeStyles-root-1 .MuiDialogTitle-root,
.MuiButton-contained {
  background-color: #580ae2 !important;
  font-family: alt;
  color: white !important;
}
.jss1 .MuiDialogTitle-root {
  background-color: #580ae2 !important;
}

